<template>
    <v-row class="fill-height" style="place-content: center">
        <v-col cols="12" class="mt-2">

            <div class="d-flex justify-center align-center">
                <div class="text-center">
                    
                    <h1 class="display-1 font-weight-bold mt-6"> 고객문의</h1>
                    <h5 class="mt-3"> 24시간 언제든지 연락주세요</h5>
                    <div class="d-flex mt-7 justify-space-between pl-1 pr-1">
                        <!-- <a href="tel:010-0000-0000">
                            <img src="/imgs/tel.png"
                                 width="55px"
                                 height="55px"
                            />
                        </a>

                        <a href="mailto:aaaaaa@test.com">
                            <img src="/imgs/mail.png"
                                 width="55px"
                                 height="55px"
                            />
                        </a>

                        <a href="http://kakao.com/talk/">
                            <img src="/imgs/kako.png"
                                 width="55px"
                                 height="55px"
                            />
                        </a> -->
                        kakao ID : mothcar
                    </div>

                    <!-- <p class="mt-5 grey--text">
                        서울시 영등포구 대림로 142, <br>
                        403호(대림동 지엔아이빌딩)
                    </p> -->
                    <p class="mt-5 grey--text">
                        서울시 서초구 서초동<br>
                        1628-8
                    </p>

                </div>
            </div>

        </v-col>
    </v-row>
</template>

<script>
    // import axios from '../../service/axios'

    export default {
        name: 'ServiceCenter',
        components: {

        },
        data(){
            return {

            }
        },
        created () {

        },
        methods: {

        },
    }

</script>

<style lang="scss">
    .preview_img{
        .v-image__image--cover {
            background-size: contain;
        }
    }
    .preview{
        .v-btn--icon.v-size--small{
            width: 10px;
            height: 10px;
        }
    }
    .subheader{
        font-size: 0.85rem;
        align-self: center;
    }
</style>